<template>
	<div class="fixed-menu">
		<div class="fixed-menu__overlay"
					@click="toggleFixedMenu"></div>
		<div class="fixed-menu__content">
			<div class="fixed-menu__close">
				<div class="btn-burger"
					@click="toggleFixedMenu">
					<span class="btn-burger__inner"></span>
				</div>
			</div>
			<nav>
				<ul class="fixed-menu__menu">
					<li 
						v-for="(link, index) in links" 
						:key="index">
						<a 
							class="fixed-menu__menu-link"
							v-smooth-scroll
							@click="toggleFixedMenu"
							:href="link.link">
							{{link.text}}
						</a>
					</li>
				</ul>
			</nav>
			<a class="fixed-menu__login" href="#">
				Вход
			</a>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			links: [
				{
					text: 'Лого',
					link: '#logo'
				},
				{
					text: 'Шрифт',
					link: '#font'
				},
				{
					text: 'Цвет',
					link: '#color'
				},
				{
					text: 'Иконки',
					link: '#icons'
				},
				{
					text: 'Имиджи',
					link: '#images'
				},
				{
					text: 'Макеты',
					link: '#brandbook'
				},
				{
					text: 'Философия',
					link: '#philosophy'
				},
			]
		}
	},
	methods: {
		toggleFixedMenu() {
			document.querySelector('body').classList.toggle('open-menu');
		}
	},
}
</script>