import './assets/scss/main.scss'

import { createApp } from 'vue'
import App from './App.vue'

import ScroolAnimation from './directives/scrollanimation'
import ScroolEvents from './directives/scrollevents'
import FillHeader from './directives/fillHeader'


import VueSmoothScroll from 'v-smooth-scroll'

const myapp = createApp(App);

myapp.directive('scrollanimation', ScroolAnimation);
myapp.directive('scrollevents', ScroolEvents);
myapp.directive('fillHeader', FillHeader);

myapp.use(VueSmoothScroll, {
  duration: 400,
	offset: -81,
  updateHistory: false,
})

myapp.mount('#app');