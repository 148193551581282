function action(el) {
	if (window.pageYOffset > 1){
		el.classList.add('header_fill');
	}
	else{
		el.classList.remove('header_fill');
	}
}


export default {
	mounted(el) {
		action(el);
		window.addEventListener('scroll', function() {
			action(el)
		})
	}
}