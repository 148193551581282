<template>
	<div class="brand-book" id="brandbook">
		<div class="container brand-book__container">
			<h2 class="title-default brand-book__title">
				Дизайн-макеты
			</h2>
			<div class="brand-book__text">
				В&nbsp;дизайн-макетах мы&nbsp;отражаем наше позиционирование с&nbsp;помощью простой сетки 
				с&nbsp;четким зонированием контента. Мы&nbsp;придерживаемся консистентного разграничения зон бренда, сообщения и&nbsp;имиджа. В&nbsp;сетке отражается наша простота, четкость 
				и&nbsp;компетентность, а&nbsp;в&nbsp;имидже&nbsp;&mdash; легкость эмоции, которую мы&nbsp;стараемся привнести.
			</div>
			<div class="brand-book__label">
				Гайдлайны Райффайзен Банка
			</div>
			<div class="brand-book__btn-wrapper">
				<a href="https://raif-brand.ru/upload/static/Raiff_guide_4.5.pdf" class="btn-default">
					Брендбук
				</a>
			</div>
		</div>
		<div class="brand-book__video">
			<video autoplay="autoplay" playsinline="" loop="loop" muted="muted">
				<source src="@/assets/video/rb_timelapse_3_down_1440p.mp4" type="video/mp4">
				<source src="@/assets/video/rb_timelapse_3_down_1440p.webm" type="video/webm">
			</video>
		</div>
	</div>
</template>