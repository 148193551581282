<template>
	<div class="logo-section" id="logo">
		<div class="container logo-section__container">
			<h2 class="title-default logo-section__title">
				Лого
			</h2>
			<p class="logo-section__text">
				Символ в виде двух перекрещенных конских голов издавна использовали народы Западной Европы как оберег, который украшал крыши их домов. А основатель группы «Райффайзен» выбрал его в качестве логотипа, обозначающего безопасность и надежность компании.
			</p>
			<div class="logo-section__image">
				<img class="from-sm" src="@/assets/img/section-logo.jpg" alt="">
				<img class="to-sm" src="@/assets/img/section-logo_mob.jpg" alt="">
			</div>
			<div class="logo-section__btn-wrapper">
				<a class="btn-default" href="https://raif-brand.ru/upload/iblock/a36/ty4u1nfnyiq0qg5gofgjfjfedz3sjzij/Raif_logo_all.zip" download>
					Скачать логотипы
				</a>
			</div>
		</div>
	</div>
</template>