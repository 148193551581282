<template>
	<div class="fonts-section" id="font">
		<div class="container fonts-section__container">
			<h2 class="title-default fonts-section__title">
				Шрифты
			</h2>
			<div class="fonts-section__text">
				Мы используем шрифты: Navigo. 
			</div>
			<div class="fonts-section__font-name">
				<b>Nav</b>igo
			</div>
			<div class="fonts-section__font-desc">
				Navigo&nbsp;&mdash; современный геометрический гротеск для офллайн и&nbsp;онлайн-коммуникаций. 
				Мы&nbsp;используем Navigo во&nbsp;всех диджитал-материалах. У&nbsp;Navigo четыре начертания: <br>
				Bold, Regular, Black и&nbsp;Light.
			</div>
			<div class="fonts-section__alphabet"
				@mouseleave="resetLettersScale()">
				<span  
					v-for="(letter, index) in letters" 
					:key="index"
					@mousemove="scaleLetters($event, index)"
					ref="alphabetLetter"
					class="fonts-section__char-cell">
					<span 
						:style="`animation-delay: ${(index * 5)/1000}s;`"
						class="fonts-section__letter">
						{{letter}}
					</span>
				</span>
			</div>
			<div class="fonts-section__weights-anim">
				<div class="fonts-section__weights">
					<span class="fonts-section__weight fonts-section__weight_bold">
							Bold
					</span>
					<span class="fonts-section__weight fonts-section__weight_medium">
							Medium
					</span>
					<span class="fonts-section__weight fonts-section__weight_regular">
							Regular
					</span>
					<span class="fonts-section__weight fonts-section__weight_light">
							Light
					</span>
				</div>
				<div class="fonts-section__weights-layer">
					<div class="fonts-section__weights">
						<span class="fonts-section__weight fonts-section__weight_bold">
								Bold
						</span>
						<span class="fonts-section__weight fonts-section__weight_medium">
								Medium
						</span>
						<span class="fonts-section__weight">
								Regular
						</span>
						<span class="fonts-section__weight fonts-section__weight_light">
								Light
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="fonts-section__sample-block">
			<div class="fonts-section__sample" v-scrollanimation:once>
				<div 
					class="fonts-section__sample-letter"
					:key="index"
					v-for="(letter, index) in sample">
					{{letter}}
					<div class="fonts-section__sample-letter-decor">
						{{letter}}
					</div>
					<div class="fonts-section__sample-letter-line fonts-section__sample-letter-line_1"></div>
					<div class="fonts-section__sample-letter-line fonts-section__sample-letter-line_2"></div>
					<div 
						class="fonts-section__sample-letter-line fonts-section__sample-letter-line_3" 
						v-if="(index==0)||(index==6)">
					</div>
					<div 
						class="fonts-section__sample-letter-line fonts-section__sample-letter-line_4" 
						v-if="(index==0)||(index==5)||(index==7)||(index==8)">
					</div>
					<div 
						class="fonts-section__sample-letter-line fonts-section__sample-letter-line_5" 
						v-if="(index==0)||(index==5)||(index==8)||(index==10)">
					</div>
					<div 
						class="fonts-section__sample-letter-line fonts-section__sample-letter-line_6" 
						v-if="(index==2)||(index==5)||(index==8)">
					</div>
					<div 
						class="fonts-section__sample-letter-line fonts-section__sample-letter-line_7" 
						v-if="(index==2)||(index==5)||(index==7)||(index==10)">
					</div>
					<div 
						class="fonts-section__sample-letter-square" 
						v-if="index==1">
						Геометричность окончаний рифмуется с&nbsp;четкими линиями нашего логотипа
					</div>
				</div>
			</div>
		</div>
		<div class="fonts-section__example">
			<div class="fonts-section__example-runner">
				Ваша карта уже готова, можете забрать ее по адресу: ул. Бакунинская, д. 10/ 12 стр.5&thinsp;💛
			</div>
		</div>
		<div class="fonts-section__btn-wrapper">
			<a href="#" class="btn-default">
				Скачать шрифты
			</a>
		</div>
	</div>
</template>


<script>
export default {
	data() {
		return {
      letters: [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '1', '2', '3', '4', '5', '6', '7', '8', '9' , '0'
      ],
			sample: 'Акселерация'
		}
	},
	methods: {
    scaleLetters (event, index) {
			if (window.innerWidth < 767) return;

      let currentTarget = event.currentTarget;

			this.resetLettersScale();

			// center
			currentTarget.style.fontWeight = '700';
			currentTarget.style.transform = 'scale(1.6)';
			currentTarget.querySelector('.fonts-section__letter').style.setProperty('font-weight', '700','important');

			let neighbors = [index-1, index+1, index-13, index+13];

			neighbors.forEach(item => {
				if (this.$refs.alphabetLetter[item]) {
					if (Math.abs(index%13 - item%13) > 3) return; //это чтобы при наведении на крайние элементы работало правильно

					this.$refs.alphabetLetter[item].style.fontWeight = '500';
					this.$refs.alphabetLetter[item].style.transform = 'scale(1.3)';
					this.$refs.alphabetLetter[item].querySelector('.fonts-section__letter').style.setProperty('font-weight', '500','important');
				}
			})

			let neighborsDiagonal = [index-14, index-12, index+14, index+12];

			neighborsDiagonal.forEach(item => {
				if (this.$refs.alphabetLetter[item]) {
					if (Math.abs(index%13 - item%13) > 3) return; //это чтобы при наведении на крайние элементы работало правильно

					this.$refs.alphabetLetter[item].style.fontWeight = '500';
					this.$refs.alphabetLetter[item].style.transform = 'scale(1.1)';
					this.$refs.alphabetLetter[item].querySelector('.fonts-section__letter').style.setProperty('font-weight', '500','important');
				}
			})
		},
		resetLettersScale() {
      for (let i = 0; i < this.$refs.alphabetLetter.length; i++) {
        this.$refs.alphabetLetter[i].setAttribute('style', '')
        // this.$refs.alphabetLetter[i].querySelector('.fonts-section__letter').style.animation = 'inherit'
					this.$refs.alphabetLetter[i].querySelector('.fonts-section__letter').style.fontWeight = '';
      }
		}
	}
}
</script>