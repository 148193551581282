<template>
	<div class="images" id="images">
		<div class="images__megaimage">
			<img src="@/assets/img/megaimage.jpg" alt="">
		</div>
		<div class="container images__container">
			<h2 class="title-default images__title">
				Визуальный стиль
			</h2>
			<div class="images__text">
				Пример. Мы&nbsp;используем фотографии в&nbsp;тонах, соответствующих гайдлайнам, 
				с&nbsp;обязательными желтыми акцентами. В&nbsp;основе фотостиля&nbsp;&mdash; легкость композиции, чистота линий и&nbsp;положительная эмоция. Модели естественны, доброжелательны, с&nbsp;тонким чувством стиля, а&nbsp;предметы лаконичны и&nbsp;продуманны.
			</div>
		</div>
		<div class="images__gallery">
			<div class="images__gallery-item"
				v-for="(image, index) in images"
				:key="index">
					<img :src="`${image.path}`" alt="">
					<div class="images__gallery-item-hover"
						:style="`background-image: url('${image.pathHover}')`"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			images: [
				{
					path: require('@/assets/img/gallery/1.jpg'),
					pathHover: require('@/assets/img/gallery/1h.png'),
				},
				{
					path: require('@/assets/img/gallery/2.jpg'),
					pathHover: require('@/assets/img/gallery/2h.png'),
				},
				{
					path: require('@/assets/img/gallery/3.jpg'),
					pathHover: require('@/assets/img/gallery/3h.png'),
				},
				{
					path: require('@/assets/img/gallery/4.jpg'),
					pathHover: require('@/assets/img/gallery/4h.png'),
				},
				{
					path: require('@/assets/img/gallery/5.jpg'),
					pathHover: require('@/assets/img/gallery/5h.png'),
				},
				{
					path: require('@/assets/img/gallery/6.jpg'),
					pathHover: require('@/assets/img/gallery/6h.png'),
				},
				{
					path: require('@/assets/img/gallery/7.jpg'),
					pathHover: require('@/assets/img/gallery/7h.png'),
				},
				{
					path: require('@/assets/img/gallery/8.jpg'),
					pathHover: require('@/assets/img/gallery/8h.png'),
				},
				{
					path: require('@/assets/img/gallery/9.jpg'),
					pathHover: require('@/assets/img/gallery/9h.png'),
				},
				{
					path: require('@/assets/img/gallery/10.jpg'),
					pathHover: require('@/assets/img/gallery/10h.png'),
				},
			]
		}
	}
}
</script>
