<template>
	<div class="colors" id="color">
		<div class="container colors__container">
			<h2 class="title-default colors__title">
				Цвета
			</h2>
			<div class="colors__text">
				<p>
					Нашими главными цветами являются насыщенный желтый и&nbsp;градации серого. Сочетание этих цветов легкое и&nbsp;в&nbsp;то&nbsp;же время строгое, что отражает наше позиционирование &laquo;Просто. Четко. Компетентно. Чтобы Легко!&raquo;
				</p>
				<p>
					В&nbsp;некоторых коммуникациях мы&nbsp;разбавляем основные цвета дополнительной палитрой, чтобы донести нужную эмоцию и&nbsp;ассоциацию.
				</p>
			</div>
		</div>
		<div class="colors__items">
			<div 
				v-for="(color, index) in mainColors"
				class="colors__item"
				:style="`background-color: ${color.hex}`"
				:class="(color.name=='white') ? 'colors__item_bordered' : ''"
				:key="index"></div>
		</div>
		<div class="colors__advanced" v-scrollanimation:once>
			<swiper
				:slides-per-view="'auto'"
				:space-between="0"
				:freeMode="true"
				:loop="true"
				:modules="modules"
			>
				<swiper-slide
					v-for="(color, index) in colors"
					:key="index">
					<div 
						class="colors__advanced-item"
						:style="`background-color: ${color.hex};`">
						<div 
							class="colors__advanced-expander"
							:style="`transition-duration: ${((index + 1) * 55)/1000}s`">
						</div>
						<div class="colors__advanced-name"
							:style="`color: ${color.fontColor}`">
							{{color.name}}
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</div>
</template>

<style>
	.before-enter {
		background-color: red;
	}

	.enter {
		background-color: cyan;
	}
</style>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';

import "swiper/css/free-mode";

import { FreeMode } from "swiper";

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
      mainColors: [
				{ 
					name: 'black',
					hex: '#2B2D33'
				},
				{ 
					name: 'yellow',
					hex: '#FEE600'
				},
				{ 
					name: 'gray',
					hex: '#55575C'
				},
				{ 
					name: 'white',
					hex: '#FFFFFF'
				},
      ],
			colors: [
				{ 
					name: 'color1',
					hex: '#EBC8C8',
					fontColor: '#854385',
				},
				{ 
					name: 'color2',
					hex: '#FACD9B',
					fontColor: '#854385',
				},
				{ 
					name: 'color3',
					hex: '#E6AF7D',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#FAA050',
					fontColor: '#854385',
				},
				{ 
					name: 'color1',
					hex: '#FA915F',
					fontColor: '#fff',
				},
				{ 
					name: 'color2',
					hex: '#FF7364',
					fontColor: '#fff',
				},
				{ 
					name: 'color3',
					hex: '#DC94C3',
					fontColor: '#fff',
				},
				{ 
					name: 'Tokyo',
					hex: '#DC94DD',
					fontColor: '#854385',
				},
				{ 
					name: 'color1',
					hex: '#BE93F8',
					fontColor: '#854385',
				},
				{ 
					name: 'color2',
					hex: '#9682EB',
					fontColor: '#854385',
				},
				{ 
					name: 'color3',
					hex: '#5A82FA',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#8CAADC',
					fontColor: '#fff',
				},
				{ 
					name: 'color1',
					hex: '#AAD9E6',
					fontColor: '#fff',
				},
				{ 
					name: 'color2',
					hex: '#5FCDA0',
					fontColor: '#fff',
				},
				{ 
					name: 'color2',
					hex: '#5ABEBE',
					fontColor: '#fff',
				},
				{ 
					name: 'color3',
					hex: '#5096AA',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#A5D05F',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#7DD27C',
					fontColor: '#854385',
				},
				{ 
					name: 'color1',
					hex: '#3C6C50',
					fontColor: '#854385',
				},
				{ 
					name: 'color2',
					hex: '#4B4B64',
					fontColor: '#854385',
				},
				{ 
					name: 'color3',
					hex: '#734B46',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#EBC8C8',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#FACD9B',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#E6AF7D',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#FAA050',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#FA915F',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#FF7364',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#DC94C3',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#DC94DD',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#BE93F8',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#9682EB',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#5A82FA',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#8CAADC',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#AAD9E6',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#5FCDA0',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#5ABEBE',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#5096AA',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#A5D05F',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#7DD27C',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#3C6C50',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#4B4B64',
					fontColor: '#854385',
				},
				{ 
					name: 'color4',
					hex: '#734B46',
					fontColor: '#854385',
				},
			]
		}
	},
	setup() {
		return {
      modules: [FreeMode],
    };
	},
}
</script>