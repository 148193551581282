// const animatedScrollObserver = new IntersectionObserver(
// 	(entries, animatedScrollObserver) => {
// 		entries.forEach(entry => {
// 			if (entry.isIntersecting) {
// 				entry.target.classList.add('scrollanimation-enter');
// 				animatedScrollObserver.unobserve(entry.target);
// 			}
// 		})
// 	}, {
// 		rootMargin: '0px 0px -50% 0px',
// 		// threshold: .15,
// 	}
// )
function action(el, once) {
	if (el.getBoundingClientRect().top - window.innerHeight / 2 < 0) {
		el.classList.remove('scrollanimation-before');
		el.classList.add('scrollanimation-enter');
	}
	else {
		if (!once) {
			el.classList.add('scrollanimation-before');
			el.classList.remove('scrollanimation-enter');
		}
	}
}



export default {
	mounted(el, arg) {
		el.classList.add('scrollanimation-before');

		action(el, (arg.arg == 'once'));

		window.addEventListener('scroll', function() {
			action(el, (arg.arg == 'once'));
		})
	}
}