<template>
	<div class="icons" id="icons">
		<div class="container icons__container">
			<h2 class="title-default icons__title">
				Галерея иконок
			</h2>
			<div class="icons__text">
				Мы разработали уникальную систему создания иконок с метафорами на самые разные темы. Логика построения элементов собрана <a href="#">в подробный гайдбук</a>.
			</div>
		</div>
		<div class="icons__image">
			<img class="from-sm" src="@/assets/img/icons.jpg" alt="">
			<img class="to-sm" src="@/assets/img/icons_mob.jpg" alt="">
		</div>
		<div class="icons__btn-wrapper">
			<a class="btn-default" href="https://raif-brand.ru/upload/Raiff_icons_all.zip" download>
				Архив иконок
			</a>
		</div>
	</div>
</template>