<template>
	<div class="philosophy" id="philosophy">
		<div class="philosophy__container container">
			<h2 class="title-default philosophy__title">
				Философия бренда
			</h2>
			<div class="philosophy__text">
				Райффайзен Банк&nbsp;&mdash; это банк с&nbsp;австрийскими корнями и&nbsp;европейской культурой. <br>
				Это качество, надежность и&nbsp;доверие. Это возможности и&nbsp;ресурсы для воплощения самых смелых идей. Это команда профессионалов, которая стремится быть ближе к&nbsp;клиенту, понимать его потребности и&nbsp;ожидания, чтобы создавать продукты и&nbsp;сервисы, делающие жизнь легче.
			</div>
			<div class="philosophy__svg">
				<svg width="320" height="320" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g class="philosophy__svg-anim-heart">
						<path d="M157.766 231.978L230.825 158.919V111.069L202.841 83.0752L157.766 231.978Z" fill="#FEE600"/>
						<path d="M103.438 82.7373L75.1162 111.069V158.919L148.194 231.997L103.438 82.7373Z" fill="#FEE600"/>
						<path d="M198.04 81.5562L155.497 89.0562L154.269 234.65L198.04 81.5562Z" fill="#FEE600"/>
						<path d="M151.672 234.631L150.444 89.0563L108.359 81.6313L151.672 234.631Z" fill="#FEE600"/>
						<path d="M235.944 105.95L205.944 75.9498C205.272 75.2778 204.474 74.7448 203.595 74.3813C202.717 74.0177 201.776 73.8308 200.825 73.8311C200.404 73.8308 199.983 73.8685 199.569 73.9436L153.022 82.1748L106.419 73.9623C106.004 73.8872 105.584 73.8496 105.163 73.8498C104.214 73.8497 103.274 74.0368 102.397 74.4004C101.52 74.764 100.724 75.2968 100.053 75.9686L70.0533 105.969C69.3809 106.641 68.8477 107.439 68.4841 108.317C68.1205 109.195 67.9338 110.137 67.9346 111.087V158.9C67.9338 159.85 68.1205 160.792 68.4841 161.67C68.8477 162.549 69.3809 163.347 70.0533 164.019L147.913 241.878C148.584 242.55 149.381 243.083 150.259 243.447C151.136 243.811 152.077 243.998 153.027 243.998C153.977 243.998 154.917 243.811 155.795 243.447C156.672 243.083 157.47 242.55 158.141 241.878L236 164.019C236.673 163.347 237.206 162.549 237.569 161.67C237.933 160.792 238.12 159.85 238.119 158.9V111.087C238.115 110.13 237.92 109.182 237.547 108.3C237.174 107.418 236.629 106.619 235.944 105.95V105.95ZM155.497 89.0748L198.041 81.5748L154.269 234.669L155.497 89.0748ZM150.444 89.0748L151.672 234.659L108.36 81.6592L150.444 89.0748ZM75.1158 158.919V111.106L103.438 82.7748L148.194 232.034L75.1158 158.919ZM230.825 158.919L157.766 231.978L202.841 83.1123L230.835 111.069L230.825 158.919Z" fill="black"/>
					</g>
					<g class="philosophy__svg-anim">
						<path d="M46.7465 215.347L34.484 187.578L31.2402 208.878L39.2277 220.513L46.7465 215.347Z" fill="#FEE600"/>
						<path d="M36.1621 186.818L49.0434 214.306L57.884 212.028L54.3496 198.359L36.1621 186.818Z" fill="#FEE600"/>
						<path d="M260 185.881L251.984 197.496L255.172 218.806L267.509 191.065L260 185.881Z" fill="#FEE600"/>
						<path d="M269.806 192.115L256.85 219.565L275.065 208.081L278.637 194.422L269.806 192.115Z" fill="#FEE600"/>
						<path d="M281.759 192.593C281.281 191.774 280.5 191.175 279.584 190.925L270.134 188.459L262.091 182.909C261.3 182.368 260.326 182.163 259.384 182.339C258.442 182.514 257.608 183.057 257.066 183.846L249.041 195.471C248.515 196.224 248.3 197.151 248.441 198.059L251.881 221.028C251.968 221.624 252.204 222.189 252.566 222.67C252.928 223.152 253.405 223.535 253.953 223.784C254.501 224.033 255.101 224.143 255.702 224.104C256.302 224.065 256.883 223.877 257.394 223.559L277.081 211.165C277.86 210.676 278.423 209.908 278.656 209.018L282.219 195.359C282.329 194.891 282.346 194.405 282.267 193.93C282.188 193.455 282.016 193.001 281.759 192.593V192.593ZM251.984 197.487L260 185.871L267.5 191.056L255.209 218.806L251.984 197.487ZM275.113 208.071L256.888 219.556L269.844 192.106L278.675 194.412L275.113 208.071Z" fill="black"/>
						<path d="M61.3899 211.119L57.8555 197.487C57.6301 196.597 57.0691 195.827 56.2899 195.34L36.6774 182.862C36.0971 182.494 35.4239 182.299 34.7368 182.3C34.2188 182.298 33.7066 182.41 33.2368 182.628C32.6901 182.877 32.2143 183.259 31.8525 183.739C31.4907 184.218 31.2544 184.781 31.1649 185.375L27.6587 208.334C27.5202 209.243 27.7312 210.171 28.2493 210.931L36.2462 222.565C36.7905 223.353 37.6243 223.894 38.5656 224.069C39.5068 224.245 40.4794 224.041 41.2712 223.503L49.3243 217.972L58.7837 215.525C59.2442 215.407 59.677 215.199 60.0572 214.914C60.4375 214.628 60.7577 214.271 60.9998 213.862C61.2418 213.453 61.4008 213 61.4678 212.529C61.5347 212.058 61.5083 211.579 61.3899 211.119ZM39.2274 220.494L31.2399 208.859L34.4837 187.559L46.7555 215.328L39.2274 220.494ZM49.043 214.287L36.1618 186.8L54.3493 198.34L57.8837 212.009L49.043 214.287Z" fill="black"/>
						<path d="M14.3187 163.737L14 145.569L35.0938 145.203L35.1969 150.912L19.025 151.194L19.1375 157.944L35.3094 157.662L35.4125 163.372L14.3187 163.737Z" fill="#2B2D33"/>
						<path d="M21.2559 140.3L21.9777 135.434L23.8527 135.519C23.1571 134.928 22.6289 134.166 22.3212 133.307C22.0135 132.448 21.9371 131.523 22.0996 130.625C22.6715 126.725 26.3652 125 30.5371 125.647C34.8777 126.284 37.8027 129.219 37.259 132.866C37.1778 133.743 36.8697 134.583 36.3651 135.305C35.8604 136.026 35.1766 136.604 34.3809 136.981L43.0621 138.266L42.3027 143.413L21.2559 140.3ZM29.8246 130.794C29.4628 130.714 29.0882 130.711 28.7251 130.785C28.362 130.858 28.0183 131.007 27.7163 131.222C27.4142 131.437 27.1605 131.712 26.9714 132.031C26.7823 132.349 26.662 132.704 26.6184 133.072C26.556 133.439 26.5709 133.816 26.6622 134.177C26.7535 134.539 26.9191 134.877 27.1485 135.171C27.3778 135.464 27.6659 135.707 27.9943 135.883C28.3227 136.059 28.6842 136.165 29.0558 136.194C29.4255 136.28 29.8089 136.288 30.1819 136.217C30.5548 136.147 30.909 136 31.222 135.786C31.5351 135.571 31.8001 135.294 32.0003 134.972C32.2005 134.649 32.3315 134.289 32.385 133.913C32.4385 133.537 32.4133 133.155 32.311 132.789C32.2087 132.424 32.0315 132.084 31.7908 131.79C31.55 131.497 31.2509 131.257 30.9124 131.085C30.5739 130.914 30.2035 130.815 29.8246 130.794V130.794Z" fill="#2B2D33"/>
						<path d="M31.1474 123.547C29.6771 123.116 28.3679 122.259 27.3858 121.083C26.4037 119.907 25.793 118.466 25.6312 116.942C25.4693 115.419 25.7636 113.881 26.4766 112.525C27.1897 111.169 28.2895 110.056 29.6364 109.325C30.9833 108.595 32.5167 108.282 34.0422 108.424C35.5676 108.567 37.0163 109.159 38.2046 110.126C39.3929 111.094 40.2671 112.392 40.7165 113.857C41.1659 115.321 41.1701 116.886 40.7286 118.354C40.4883 119.353 40.0405 120.29 39.4144 121.105C38.7884 121.92 37.998 122.594 37.0945 123.084C36.1911 123.574 35.1948 123.868 34.1702 123.948C33.1457 124.028 32.1158 123.891 31.1474 123.547ZM34.1193 113.694C32.4693 113.197 30.8943 113.572 30.3693 115.241C29.8443 116.91 30.988 118.054 32.6286 118.569C34.2692 119.085 35.8724 118.719 36.3786 117.05C36.8849 115.382 35.7318 114.191 34.0911 113.694H34.1193Z" fill="#2B2D33"/>
						<path d="M36.4809 106.841C32.609 105.078 30.5746 101.066 32.5433 96.7436C34.6527 92.1124 38.5152 92.7218 38.5152 92.7218L40.2777 93.528L38.5902 97.2311C38.5902 97.2311 37.3902 97.0249 36.6684 98.6186C35.9465 100.212 37.0434 101.431 38.6277 102.106C40.2121 102.781 41.7121 102.669 42.3777 101.216C43.1277 99.5468 42.134 98.8155 42.134 98.8155L43.8215 95.0655L45.5934 95.8718C45.5934 95.8718 48.5746 98.4686 46.5309 103.053C44.4871 107.637 40.2402 108.547 36.4809 106.841Z" fill="#2B2D33"/>
						<path d="M42.3973 87.9688L40.4379 91.1845L36.8848 89.0189L43.5316 78.1157L47.0848 80.3001L45.1067 83.5439L53.7598 88.8126L51.0504 93.2564L42.3973 87.9688Z" fill="#2B2D33"/>
						<path d="M50.31 80.6281C49.1065 79.6811 48.2113 78.3982 47.7376 76.942C47.264 75.4857 47.2333 73.9217 47.6495 72.448C48.0656 70.9743 48.9099 69.6573 50.0752 68.6638C51.2406 67.6704 52.6746 67.0453 54.1956 66.8676C55.7166 66.69 57.2561 66.9679 58.6191 67.6661C59.982 68.3642 61.1069 69.4513 61.8515 70.7894C62.596 72.1276 62.9265 73.6566 62.8012 75.1828C62.6759 76.709 62.1003 78.1637 61.1475 79.3624C60.5543 80.2042 59.7903 80.9113 58.9054 81.4377C58.0204 81.9641 57.0343 82.2979 56.0116 82.4174C54.9888 82.5368 53.9524 82.4392 52.9699 82.1309C51.9874 81.8225 51.0811 81.3105 50.31 80.6281V80.6281ZM56.7131 72.5656C55.3631 71.5062 53.7599 71.2625 52.6724 72.6312C51.5849 74 52.2037 75.4906 53.5443 76.5593C54.885 77.6281 56.4975 77.8999 57.585 76.5593C58.6725 75.2187 58.0537 73.6343 56.7131 72.5656Z" fill="#2B2D33"/>
						<path d="M63.209 68.0376L66.9027 64.2876L70.6527 68.0376L66.959 71.7876L63.209 68.0376Z" fill="#2B2D33"/>
						<path d="M80.8346 49.6531L78.5002 51.3687C73.7471 54.8656 69.8283 54.7156 66.2564 49.8687L63.2939 45.8375L67.8877 42.4531L70.8595 46.4844C72.3595 48.5281 73.8127 48.8844 75.7346 47.4219L78.0221 45.8L72.8752 38.8063L77.4689 35.4219L89.9752 52.4094L85.3721 55.7937L80.8346 49.6531Z" fill="#2B2D33"/>
						<path d="M88.5402 45.0502C88.0181 44.1536 87.6894 43.1577 87.5754 42.1266C87.4613 41.0954 87.5643 40.0517 87.8778 39.0628C88.1912 38.0738 88.7082 37.1614 89.3955 36.3842C90.0828 35.607 90.9251 34.9823 91.8683 34.5502C96.3308 32.2158 99.6964 33.9127 101.543 37.447C101.842 38.042 102.066 38.6719 102.209 39.322L93.7714 43.7283C94.1596 44.2598 94.7316 44.628 95.376 44.7614C96.0205 44.8948 96.6916 44.7838 97.2589 44.4502C99.1339 43.5127 98.9558 42.4158 98.9558 42.4158L102.771 40.4189L103.709 42.1439C103.709 42.1439 103.962 45.5377 99.0777 48.0877C94.3339 50.5533 90.4808 48.7627 88.5402 45.0502ZM96.7433 38.947C96.6009 38.6892 96.4074 38.4631 96.1746 38.2825C95.9418 38.102 95.6746 37.9709 95.3894 37.8972C95.1042 37.8235 94.807 37.8087 94.5159 37.8538C94.2248 37.8989 93.9459 38.003 93.6964 38.1596C93.1584 38.4256 92.7369 38.8802 92.5122 39.4368C92.2875 39.9934 92.2752 40.6132 92.4777 41.1783L96.7433 38.947Z" fill="#2B2D33"/>
						<path d="M105.735 33.3312L102.2 34.6531L100.747 30.7531L112.71 26.2812L114.172 30.1812L110.61 31.5125L114.163 40.9906L109.288 42.8094L105.735 33.3312Z" fill="#2B2D33"/>
						<path d="M115.869 25.297L120.932 24.097L122.179 29.3188L124.654 23.2063L129.341 22.1001L129.782 23.9751L127.363 29.6001L132.697 33.9407L133.157 35.8626L127.982 37.0813L122.929 32.4876L124.232 37.9814L119.169 39.1907L115.869 25.297Z" fill="#2B2D33"/>
						<path d="M132.706 28.7374C132.543 27.2131 132.836 25.6745 133.548 24.317C134.26 22.9595 135.36 21.8443 136.708 21.1131C138.055 20.3819 139.59 20.0677 141.116 20.2103C142.642 20.3529 144.092 20.9459 145.281 21.9141C146.469 22.8822 147.344 24.1818 147.792 25.6478C148.241 27.1138 148.244 28.68 147.8 30.1476C147.357 31.6152 146.488 32.918 145.302 33.8904C144.117 34.8629 142.67 35.4612 141.144 35.6093C140.13 35.7654 139.095 35.7071 138.106 35.4381C137.116 35.1692 136.194 34.6956 135.398 34.0479C134.603 33.4003 133.953 32.593 133.489 31.6783C133.025 30.7635 132.759 29.7617 132.706 28.7374V28.7374ZM142.953 27.7156C142.775 26.0093 141.828 24.6874 140.094 24.9031C138.36 25.1187 137.713 26.5531 137.881 28.2593C138.05 29.9656 138.978 31.3156 140.694 31.1374C142.41 30.9593 143.122 29.3749 142.953 27.6687V27.7156Z" fill="#2B2D33"/>
						<path d="M150.837 29.1406L156.125 29.225L156.04 34.5125L150.753 34.4281L150.837 29.1406Z" fill="#2B2D33"/>
						<path d="M170.412 35.7313L164.787 34.7938L168.312 14L173.937 14.9375L172.531 23.225L180.425 16.0344L185.187 16.85L184.822 19.0063L177.322 25.6625L182.947 35.6L182.571 37.7844L177.228 36.8469L171.893 27.275L170.412 35.7313Z" fill="#2B2D33"/>
						<path d="M184.982 31.1935C185.454 29.7368 186.349 28.4533 187.552 27.5055C188.755 26.5576 190.212 25.9883 191.739 25.8695C193.265 25.7507 194.793 26.0879 196.128 26.8383C197.463 27.5886 198.545 28.7184 199.238 30.0845C199.93 31.4505 200.201 32.9914 200.017 34.5117C199.832 36.032 199.201 37.4633 198.202 38.6243C197.203 39.7853 195.882 40.6237 194.407 41.0333C192.931 41.4428 191.367 41.405 189.913 40.9248C188.919 40.6576 187.993 40.1841 187.194 39.5353C186.395 38.8865 185.742 38.0768 185.277 37.1589C184.812 36.241 184.545 35.2354 184.494 34.2076C184.443 33.1799 184.609 32.1529 184.982 31.1935V31.1935ZM194.75 34.4373C195.294 32.806 194.966 31.2123 193.307 30.6873C191.647 30.1623 190.494 31.2216 189.922 32.8529C189.35 34.4841 189.678 36.0873 191.338 36.6029C192.997 37.1185 194.216 36.0591 194.75 34.4373Z" fill="#2B2D33"/>
						<path d="M206.91 38.4499L203.16 45.5843L198.997 43.4093L205.606 30.7437L211.513 33.828L210.913 40.9999L216.397 36.378L222.238 39.4155L215.628 52.0905L211.241 49.7937L214.991 42.6124L209.788 46.9437L206.347 45.1437L206.91 38.4499Z" fill="#2B2D33"/>
						<path d="M226.147 42.0688L237.397 50.4126L228.865 61.8782L224.637 58.7376L230.684 50.6188L227.815 48.4907L221.769 56.6095L217.597 53.497L226.147 42.0688Z" fill="#2B2D33"/>
						<path d="M235.138 57.978C235.831 57.2058 236.678 56.5871 237.624 56.1619C238.571 55.7366 239.596 55.5142 240.633 55.5089C241.671 55.5036 242.698 55.7155 243.649 56.1311C244.6 56.5467 245.453 57.1567 246.154 57.9218C249.735 61.4562 249.154 65.178 246.35 68.028C245.878 68.4966 245.345 68.9001 244.766 69.228L237.997 62.5624C237.609 63.0931 237.432 63.7501 237.503 64.4042C237.575 65.0583 237.888 65.6621 238.382 66.0968C239.882 67.5781 240.838 67.0999 240.838 67.0999L243.904 70.1187L242.535 71.4968C242.535 71.4968 239.385 72.7718 235.457 68.9093C231.529 65.0468 232.194 60.9593 235.138 57.978ZM243.454 63.9124C243.656 63.6983 243.813 63.4448 243.914 63.1678C244.015 62.8907 244.058 62.5959 244.04 62.3015C244.023 62.0071 243.945 61.7195 243.812 61.4564C243.679 61.1933 243.493 60.9603 243.266 60.7718C242.848 60.3421 242.287 60.0809 241.689 60.0377C241.091 59.9945 240.498 60.1723 240.022 60.5374L243.454 63.9124Z" fill="#2B2D33"/>
						<path d="M251.552 70.7472L249.218 67.7941L252.471 65.2065L260.412 75.2097L257.149 77.7972L254.787 74.8159L246.855 81.1159L243.621 77.0378L251.552 70.7472Z" fill="#2B2D33"/>
						<path d="M255.584 80.7687C256.445 80.1883 257.417 79.7943 258.439 79.6121C259.461 79.4299 260.51 79.4635 261.519 79.7107C262.527 79.9579 263.472 80.4133 264.294 81.0476C265.116 81.6818 265.796 82.481 266.29 83.3937C268.915 87.6874 267.434 91.1562 264.031 93.2468C263.457 93.5825 262.843 93.844 262.203 94.0249L257.244 85.9249C256.741 86.3489 256.415 86.9442 256.326 87.5954C256.238 88.2467 256.394 88.9075 256.765 89.4499C257.862 91.2499 258.903 91.0155 258.903 91.0155L261.144 94.6812L259.494 95.6937C259.494 95.6937 256.119 96.1718 253.25 91.4656C250.522 86.9187 252.012 82.9531 255.584 80.7687ZM262.222 88.5499C262.47 88.3906 262.682 88.1823 262.847 87.9378C263.011 87.6934 263.124 87.4179 263.179 87.1283C263.233 86.8387 263.228 86.541 263.163 86.2535C263.099 85.966 262.976 85.6947 262.803 85.4562C262.503 84.9358 262.021 84.5447 261.45 84.3578C260.879 84.1709 260.259 84.2015 259.709 84.4437L262.222 88.5499Z" fill="#2B2D33"/>
						<path d="M271.343 93.2563L273.406 98.0282L268.812 100.016L270.35 103.55L274.943 101.563L277.006 106.334L263.881 112.016L261.809 107.244L266.496 105.219L264.959 101.684L260.271 103.7L258.209 98.9282L271.343 93.2563Z" fill="#2B2D33"/>
						<path d="M275.14 114.294L274.1 110.675L278.103 109.522L281.628 121.794L277.625 122.947L276.575 119.3L266.844 122.113L265.409 117.106L275.14 114.294Z" fill="#2B2D33"/>
						<path d="M282.397 125.075L283.184 130.213L278.234 130.972L278.815 134.778L283.765 134.028L284.553 139.166L270.425 141.331L269.637 136.185L274.672 135.416L274.09 131.61L269.056 132.378L268.269 127.231L282.397 125.075Z" fill="#2B2D33"/>
						<path d="M277.71 142.953C279.242 142.918 280.749 143.339 282.041 144.162C283.334 144.984 284.352 146.172 284.969 147.575C285.585 148.978 285.77 150.532 285.502 152.04C285.234 153.548 284.524 154.943 283.462 156.047C282.4 157.151 281.034 157.915 279.537 158.242C278.04 158.569 276.48 158.444 275.055 157.883C273.629 157.322 272.402 156.35 271.53 155.091C270.657 153.831 270.178 152.341 270.154 150.81C270.08 149.784 270.222 148.755 270.571 147.788C270.921 146.822 271.47 145.939 272.182 145.198C272.895 144.458 273.755 143.875 274.707 143.488C275.66 143.101 276.683 142.919 277.71 142.953ZM277.879 153.266C279.594 153.266 280.982 152.403 280.954 150.66C280.926 148.916 279.51 148.147 277.795 148.175C276.079 148.203 274.654 149.01 274.682 150.753C274.71 152.497 276.201 153.275 277.916 153.247L277.879 153.266Z" fill="#2B2D33"/>
						<path d="M275.722 160.925L275.235 166.184L269.966 165.706L270.453 160.438L275.722 160.925Z" fill="#2B2D33"/>
						<path d="M75.3311 251.019L73.0904 249.144C68.5248 245.394 67.7092 241.569 71.5248 236.909L74.6936 233.038L79.1092 236.666L75.9405 240.613C74.328 242.563 74.3373 244.063 76.1842 245.581L78.3592 247.363L83.8717 240.65L88.2873 244.269L74.9092 260.572L70.5217 256.916L75.3311 251.019Z" fill="#2B2D33"/>
						<path d="M87.0308 256.25L83.8059 254.3L85.9621 250.737L96.884 257.356L94.7277 260.919L91.484 258.95L86.234 267.612L81.7715 264.912L87.0308 256.25Z" fill="#2B2D33"/>
						<path d="M94.6907 264.322C95.3015 262.917 96.316 261.725 97.6053 260.897C98.8947 260.069 100.401 259.643 101.933 259.672C103.465 259.702 104.953 260.186 106.21 261.062C107.466 261.939 108.434 263.17 108.99 264.597C109.547 266.025 109.666 267.586 109.334 269.081C109.002 270.577 108.234 271.941 107.125 272.999C106.017 274.057 104.62 274.762 103.11 275.025C101.601 275.288 100.047 275.096 98.6469 274.475C97.6859 274.112 96.8119 273.55 96.0818 272.828C95.3516 272.105 94.7815 271.236 94.4085 270.279C94.0354 269.322 93.8678 268.297 93.9164 267.27C93.965 266.244 94.2288 265.24 94.6907 264.322ZM104.066 268.503C104.769 266.937 104.591 265.325 102.997 264.612C101.403 263.9 100.119 264.866 99.4157 266.431C98.7125 267.997 98.8532 269.637 100.447 270.341C102.041 271.044 103.4 270.069 104.094 268.503H104.066Z" fill="#2B2D33"/>
						<path d="M111.772 270.856L112.353 268.747C114.013 262.813 116.947 259.513 123.05 260.656L124.484 260.947C125.853 261.181 126.191 260.806 126.191 260.806L129.941 261.838L129.416 263.713C128.356 265.316 126.988 265.935 123.791 265.363L121.916 265.016C119.328 264.556 118.306 265.381 117.444 267.744C118.129 267.198 118.942 266.834 119.806 266.687C120.669 266.539 121.557 266.613 122.384 266.9C126.5 268.044 128.009 271.588 126.791 275.919C125.666 279.931 121.916 282.416 117.144 281.094C112.372 279.772 110.384 275.76 111.772 270.856ZM121.775 274.55C122.216 272.956 121.775 271.447 119.947 270.95C118.119 270.453 116.994 271.494 116.544 273.097C116.094 274.7 116.544 276.256 118.334 276.744C120.125 277.231 121.306 276.181 121.756 274.55H121.775Z" fill="#2B2D33"/>
						<path d="M130.812 269.525L135.987 270.059L135.574 273.95L137.309 274.128C141.584 274.578 143.374 276.941 143.074 279.856C142.774 282.772 140.534 284.9 136.174 284.45L129.321 283.728L130.812 269.525ZM136.146 280.625C137.084 280.728 137.787 280.316 137.89 279.369C137.993 278.422 137.44 277.962 136.465 277.859L135.209 277.728L134.918 280.541L136.146 280.625ZM144.846 270.987L149.993 271.531L148.503 285.734L143.356 285.2L144.846 270.987Z" fill="#2B2D33"/>
						<path d="M158.91 281.141H159.163C160.588 280.963 160.916 279.819 160.738 277.832L160.176 271.11L172.176 269.854L173.648 284.047L168.473 284.582L167.423 274.485L164.966 274.747L165.276 278.019C165.623 281.91 165.013 284.947 160.841 285.379L159.36 285.538L158.91 281.141Z" fill="#2B2D33"/>
						<path d="M176.094 276.528C175.805 275.536 175.727 274.495 175.863 273.47C175.999 272.446 176.347 271.461 176.885 270.579C177.424 269.697 178.14 268.937 178.988 268.347C179.836 267.757 180.798 267.351 181.813 267.153C186.706 265.944 189.566 268.391 190.532 272.263C190.686 272.91 190.752 273.576 190.728 274.241L181.503 276.528C181.755 277.136 182.223 277.628 182.818 277.909C183.412 278.19 184.09 278.239 184.719 278.047C186.772 277.541 186.857 276.481 186.857 276.481L191.028 275.441L191.497 277.316C191.497 277.316 190.935 280.672 185.591 282.003C180.425 283.25 177.106 280.597 176.094 276.528ZM185.469 272.544C185.392 272.26 185.258 271.995 185.075 271.765C184.893 271.535 184.665 271.344 184.406 271.204C184.148 271.065 183.863 270.98 183.57 270.954C183.278 270.928 182.983 270.962 182.703 271.053C182.121 271.188 181.608 271.531 181.263 272.018C180.917 272.506 180.763 273.103 180.828 273.697L185.469 272.544Z" fill="#2B2D33"/>
						<path d="M191.094 265.043L200.891 261.293L202.381 265.184L197.441 267.059L201.059 276.518L196.203 278.393L191.094 265.043Z" fill="#2B2D33"/>
						<path d="M202.709 260.553L207.303 258.116L209.815 262.869L210.687 256.306L214.934 254.056L215.871 255.772L214.934 261.837L221.187 264.65L222.125 266.384L217.437 268.869L211.39 265.681L214.034 270.678L209.44 273.106L202.709 260.553Z" fill="#2B2D33"/>
						<path d="M220.081 259.25C219.179 258.011 218.664 256.533 218.604 255.001C218.543 253.47 218.939 251.955 219.74 250.649C220.542 249.343 221.714 248.305 223.107 247.666C224.499 247.027 226.051 246.816 227.564 247.06C229.077 247.304 230.483 247.992 231.604 249.036C232.725 250.081 233.511 251.435 233.862 252.927C234.212 254.419 234.111 255.981 233.572 257.416C233.033 258.85 232.08 260.092 230.834 260.984C230.035 261.629 229.108 262.099 228.115 262.362C227.122 262.626 226.085 262.678 225.071 262.514C224.057 262.351 223.088 261.975 222.229 261.413C221.369 260.851 220.637 260.114 220.081 259.25V259.25ZM228.443 253.25C227.44 251.862 225.959 251.187 224.543 252.209C223.128 253.231 223.306 254.825 224.309 256.222C225.312 257.619 226.784 258.322 228.2 257.309C229.615 256.297 229.447 254.675 228.443 253.25Z" fill="#2B2D33"/>
						<path d="M225.734 237.632L229.981 233.816L238.953 244.241L235.128 247.672L225.734 237.632ZM236.459 249.238L240.378 245.732L243.913 249.66L239.994 253.175L236.459 249.238Z" fill="#2B2D33"/>
						</g>
				</svg>

			</div>	
		</div>
	</div>
</template>