<template>
	<div class="form-style">
		<div class="container form-style__container">
			<h2 class="title-default form-style__title">
				Фирменный стиль
			</h2>
			<p class="form-style__text">
				Фирменный стиль Райффайзен Банка тоже отражает наши принципы: он&nbsp;простой 
				и&nbsp;понятный, четкий и&nbsp;логичный, яркий и&nbsp;легкий. Стиль предусматривает несколько основных элементов.
			</p>
		</div>
		<div class="form-style__items">
			<a class="form-style__item" href="#logo"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/logo.svg" alt="">
				<span class="form-style__item-text">
					Лого
				</span>
			</a>
			<a class="form-style__item" href="#images"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/promo.jpg" alt="">
				<span class="form-style__item-text">
					Имиджи
				</span>
			</a>
			<a class="form-style__item" href="#philosophy"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/philosophy.jpg" alt="">
				<span class="form-style__item-text">
					Философия
				</span>
			</a>
			<a class="form-style__item" href="#color"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/color.svg" alt="">
				<span class="form-style__item-text">
					Цвет
				</span>
			</a>
			<a class="form-style__item" href="#icons"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/icon.jpg" alt="">
				<span class="form-style__item-text">
					Иконки
				</span>
			</a>
			<a class="form-style__item" href="#font"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/font.jpg" alt="">
				<span class="form-style__item-text">
					Шрифты
				</span>
			</a>
			<a class="form-style__item" href="#brandbook"
				v-smooth-scroll>
				<img src="@/assets/img/form-style/citylight.png" alt="">
				<span class="form-style__item-text">
					Реклама
				</span>
			</a>
		</div>
	</div>
</template>