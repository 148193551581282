<template>
  <TheHeader/>
  <main>
    <BrandSystem/>
    <ThePlanes/>
    <ThePrinciples/>
    <FormStyle/>
    <TheLogo/>
    <TheFonts/>
    <TheColors/>
    <IconsGallery/>
    <TheImages/>
    <BrandBook/>
    <ThePhilosophy/>
  </main>
  <TheFooter/>
  <FixedMenu/>
</template>

<script>
import TheHeader from './components/TheHeader.vue'
import BrandSystem from './components/BrandSystem.vue'
import ThePrinciples from './components/ThePrinciples.vue'
import FormStyle from './components/FormStyle.vue'
import TheLogo from './components/TheLogo.vue'
import TheFonts from './components/TheFonts.vue'
import TheColors from './components/TheColors.vue'
import IconsGallery from './components/IconsGallery.vue'
import TheImages from './components/TheImages.vue'
import BrandBook from './components/BrandBook.vue'
import ThePhilosophy from './components/ThePhilosophy.vue'
import TheFooter from './components/TheFooter.vue'
import ThePlanes from './components/ThePlanes.vue'
import FixedMenu from './components/FixedMenu.vue'

export default {
  name: 'App',
  components: {
    TheHeader,
    BrandSystem,
    ThePrinciples,
    FormStyle,
    TheLogo,
    TheFonts,
    TheColors,
    IconsGallery,
    TheImages,
    BrandBook,
    ThePhilosophy,
    ThePlanes,
    TheFooter,
    FixedMenu
  },
  mounted () {
    document.body.classList.add('body')
  },
  unmounted () {
    document.body.classList.remove('body')
  }
}
</script>

<style>

</style>
