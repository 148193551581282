<template>
	<footer class="footer">
		<a class="logo footer__logo" href="/">
			<img src="@/assets/img/logo.svg" alt="">
		</a>
		<div class="footer__copyright">©&nbsp;2020&nbsp;АО «Райффайзенбанк»</div>
		<div class="footer__made-in">
			Задизайнено в <a target="_blank" href="https://www.artlebedev.ru/raiffeisen/brand-system/">Студии Артемия Лебедева</a>
		</div>
	</footer>
</template>