<template>
	<header class="header" v-fillHeader>
		<a class="logo header__logo" href="/">
			<img src="@/assets/img/logo.svg" alt="">
		</a>
		<div class="header__right">
			<nav>
				<ul class="header__menu">
					<li 
						v-for="(link, index) in links" 
						:key="index">
						<a 
							class="header__menu-link"
							v-smooth-scroll
							:href="link.link">
							{{link.text}}
						</a>
					</li>
				</ul>
			</nav>
			<a class="header__login" href="#">
				Вход
			</a>
			<div class="btn-burger header__burger"
				@click="toggleFixedMenu">
				<span class="btn-burger__inner"></span>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	data() {
		return {
			links: [
				{
					text: 'Лого',
					link: '#logo'
				},
				{
					text: 'Шрифт',
					link: '#font'
				},
				{
					text: 'Цвет',
					link: '#color'
				},
				{
					text: 'Иконки',
					link: '#icons'
				},
				{
					text: 'Имиджи',
					link: '#images'
				},
				{
					text: 'Макеты',
					link: '#brandbook'
				},
				{
					text: 'Философия',
					link: '#philosophy'
				},
			]
		}
	},
	methods: {
		toggleFixedMenu() {
			document.querySelector('body').classList.toggle('open-menu');
		}
	},
}
</script>