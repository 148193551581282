<template>
	<div class="principles" v-scrollevents:zero>
		<div class="principles__desc">
			<div class="container principles__desc-container">
				<div class="principles__icon">
					<img src="@/assets/img/logo-small.svg" alt="">
				</div>
				<div class="principles__desc-text" v-scrollevents:first>
					С нами легко, потому что
					мы&nbsp;следуем трем принципам
				</div>
				<div v-scrollevents:second></div>
			</div>
		</div>
		<div class="principles__tagline" v-scrollevents:third>
			<!-- <div class="container principles__tagline-container"> -->
				<div class="principles__tagline-items">
					<div class="principles__tagline-item" style="transition-delay: .15s">
						Просто. 
					</div>
					<div class="principles__tagline-item" style="transition-delay: .3s">
						Четко. 
					</div>
					<div class="principles__tagline-item" style="transition-delay: .45s">
							Компетентно.
					</div>
				</div>
			<!-- </div> -->
		</div>
	</div>
</template>