function action(el, pos) {
	if (el.getBoundingClientRect().top - window.innerHeight / 2 < 0) {
		document.querySelector('#app').classList.add('ani-' + pos);
	}
	else {
		document.querySelector('#app').classList.remove('ani-' + pos);
	}
}



export default {
	mounted(el, arg) {
		action(el, arg.arg);

		window.addEventListener('scroll', function() {
			action(el, arg.arg);
		})
	}
}